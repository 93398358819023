<template>
  <div class="donut-container-">
    <div class="donut-container-donutIconic">
      <div class="donutIconic" :style="getStyle" :data="tot" />
    </div>
    <div class="donut-container-legend">
      <div v-for="sec in sectors" :key="coid + sec.label" style="width: 100%;">
        <div v-if="sec.value > 0" class="donut-container-legend-text">
          <div>{{ sec.value }}</div>
          <div class="littlepoint" :class="sec.label" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    coid: {
      type: Number,
      default: 0
    },
    tot: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 0
    },
    sectors: {
      type: Array,
      default: () => { return [] }
    }
  },
  computed: {
    getStyle() {
      let almostOneGreater = this.sectors.find(s => s.value && s.value > 0)
      if (almostOneGreater) {
        let correnti = this.percentageDeg(this.sectors.find(s => s.label === 'Correnti').value)
        let scadute = this.percentageDeg(this.sectors.find(s => s.label === 'Scadute').value)
        let archiviate = this.percentageDeg(this.sectors.find(s => s.label === 'Archiviate').value)
        let cancellate = this.percentageDeg(this.sectors.find(s => s.label === 'Cancellate').value)
        return 'background: conic-gradient( #A9DC62 0deg ' + correnti + 'deg, #FFD862 ' + correnti + 'deg ' + (correnti + scadute) + 'deg, #FF6188 ' + (correnti + scadute) + 'deg ' + (correnti + scadute + archiviate) + 'deg, #dc3545 ' + (correnti + scadute + archiviate) + 'deg ' + (correnti + scadute + archiviate + cancellate) + 'deg );'
      } else {
        return 'background: #8ba8cb'
      }
    }
  },
  methods: {
    percentageDeg(num) {
      if (!num) return 0
      return (num / this.tot) * 360
    }
  }
}
</script>

<style lang="scss" scoped>
.donut-container-legend {
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 10px;
  min-width: 35px;

  .donut-container-legend-text {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 2px;
    font-size: 12px;

    &.hidden {
      opacity: 0;
      height: 0;
      width: 0;
      margin: 0;
    }

    .littlepoint {
      width: 8px;
      height: 8px;
      border-radius: 1px;
      margin: 2px;
      margin-left: 5px;

      &.Correnti {
        background-color: #A9DC62;
      }

      &.Scadute {
        background-color: #FFD862;
      }

      &.Archiviate {
        background-color: #FF6188;
      }

      &.Cancellate {
        background-color: #dc3545;
      }
    }
  }
}

.donut-container- {
  display: flex;
  justify-content: center;
  align-items: center;
}

.donut-container-donutIconic {
  position: relative;
  width: 35px;
  height: 35px;
}

.donutIconic {
  display: block;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  //position: absolute;
  //top: 50%;
  //left: 50%;
  //transform: translate(-50%, -50%);

  &::after {
    content: attr(data);
    display: block;
    position: absolute;
    width: 31px;
    height: 31px;
    background: #005895;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 31px;
    font-weight: 600;
  }
}
</style>