<template>
  <div>
    <form>
      <div
        class="group group-100 files"
        :class="{ 'placeholder-cover': cover_url === 'https://s3.gcpstaging.cving.com/s3/companies/covers/cover_placeholder.png' }"
        :style="{ backgroundImage: 'url(' + cover_url + ')' }"
      >
        <div class="absolute">
          <div
            class="img-logo"
            :class="{ 'placeholder-logo': logo_url === 'https://s3.gcpstaging.cving.com/s3/companies/logos/logo_placeholder.png' }"
            :style="{ backgroundImage: 'url(' + logo_url + ')' }"
            @click.stop="triggerInput('logo')"
          />
          <label for="logo">Logo</label>
          <input id="logo" class="cursor-pointer" type="file" accept=".png,.jpg,jpeg" @change.stop="onFileChange($event, 'logo')">
        </div>
        <label for="cover">Immagine di copertina</label>
        <input id="cover" class="input-cover cursor-pointer" type="file" accept=".png,.jpg,jpeg" @change="onFileChange($event, 'cover')">
      </div>

      <div class="group group-50">
        <material-input
          type="text"
          label="Nome azienda"
          autocomplete="off"
          placeholder="Nome azienda"
          :value="co_name"
          @input="(e) => (co_name = e)"
        />
      </div>

      <div class="group group-50">
        <material-input
          type="text"
          label="Indirizzo"
          autocomplete="off"
          placeholder="Indirizzo"
          :value="address"
          @input="(e) => (address = e)"
        />
      </div>
      <div class="group group-50">
        <material-input
          type="text"
          label="Città"
          autocomplete="off"
          placeholder="Città"
          :value="city"
          @input="(e) => (city = e)"
        />
      </div>
      <div class="group group-50">
        <material-input
          type="text"
          label="Cap"
          autocomplete="off"
          placeholder="Cap"
          :value="postal_code"
          @input="(e) => (postal_code = e)"
        />
      </div>
      <div class="group group-50">
        <material-input
          type="text"
          label="Regione"
          autocomplete="off"
          placeholder="Regione"
          :value="region_state"
          @input="(e) => (region_state = e)"
        />
      </div>
      <div class="group group-50">
        <material-input
          type="text"
          label="Paese"
          autocomplete="off"
          placeholder="Paese"
          :value="country"
          @input="(e) => (country = e)"
        />
      </div>
      <div class="group group-50">
        <material-input
          type="text"
          label="Codice Paese"
          autocomplete="off"
          placeholder="Codice Paese"
          :value="country_code"
          @input="(e) => (country_code = e)"
        />
      </div>
      <div class="group group-100">
        <!-- <textarea class="inputMaterial" v-model="co_description" rows="5" />
				<span class="bar"></span>
				<label>Descrizione</label> -->
        <material-input
          type="textarea"
          label="Descrizione"
          autocomplete="off"
          placeholder="Descrizione"
          rows="5"
          :value="co_description"
          @input="(e) => (co_description = e)"
        />
      </div>
      <div class="group group-50">
        <material-input
          type="text"
          label="Website"
          autocomplete="off"
          placeholder="Website"
          :value="website"
          @input="(e) => (website = e)"
        />
      </div>
      <div class="group group-50">
        <material-input
          type="text"
          label="Privacy Policy"
          autocomplete="off"
          placeholder="Privacy Policy"
          :value="privacy_policy_url"
          @input="(e) => (privacy_policy_url = e)"
        />
      </div>
      <div class="group group-50">
        <material-input
          type="text"
          label="Corporate Video"
          autocomplete="off"
          placeholder="Corporate Video"
          :value="corporate_video_url"
          @input="(e) => (corporate_video_url = e)"
        />
      </div>
      <div class="group group 100" />
      <div v-if="permissions.canUse('CONTRACT_MANAGEMENT_VIEW')" class="group group-50">
        <span>Data inizio contratto</span>
        <material-date-picker
          label="Data di inizio"
          :bordered="true"
          :required="false"
          :date="contract_start_date"
          style="margin-top: 10px !important;"
          @update:date="contract_start_date = $event"
        />
      </div>

      <div v-if="permissions.canUse('CONTRACT_MANAGEMENT_VIEW')" class="group group-50">
        <span>Data fine contratto</span>
        <material-date-picker
          label="Data di fine"
          :bordered="true"
          :required="false"
          :date="contract_end_date"
          style="margin-top: 10px !important;"
          @update:date="contract_end_date = $event"
        />
      </div>

      <span style="margin-top: 20px;">Email alert fine contratto (14gg dalla scadenza)</span>
      <Multiselect
        v-if="permissions.canUse('CONTRACT_MANAGEMENT_VIEW')"
        v-model="contract_alert_emails"
        mode="tags"
        :searchable="true"
        :can-clear="true"
        :close-on-select="true"
        :clear-on-select="true"
        :clear-on-blur="true"
        :create-option="true"
        :options="contract_alert_emails"
        placeholder="Email"
        no-options-text="Email"
      >
        <template #tag="{ option, handleTagRemove }">
          <div class="multiselect-tag" @click="handleTagRemove(option, $event)">
            <span>{{ option.value }}</span>
            <span class="multiselect-tag__remove" @click="handleTagRemove(option, $event)">x</span>
          </div>
        </template>
      </Multiselect>
      <div v-if="multi_store" class="group group-50" style="margin-top: 20px;">
        <div class="material-checkbox">
          <input id="multi_store-anagrafica" v-model="multi_store" type="checkbox" :value="multi_store" disabled>
          <label for="multi_store-anagrafica">Premium Retail Recruiting Model</label>
        </div>
      </div>
      <div v-if="multi_store" class="group group-50" style="position:relative;margin-top: 20px;">
        <input
          id="num_max_recruiters"
          v-model="num_max_recruiters"
          autocomplete="off"
          type="number"
          class="inputMaterial"
        >
        <span class="bar" />
        <label>Recruiters Soft Limit Counter</label>
      </div>

      <div style="width: 100%; text-align: right; padding-bottom: 10px">
        <material-button v-if="permissions.canUse('COMPANY_MODIFY')" text="salva l'anagrafica dell'azienda" type="success" @click.prevent="saveForm()" />
      </div>
    </form>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { sbapibackoffice } from '@/api'
import { permissionsStore } from '@/stores/permissions'
import { spinnerStore } from '@/stores/spinner'
import dayjs from 'dayjs'
import { parseISO } from 'date-fns'
import Multiselect from '@vueform/multiselect'

export default {
  methods: { parseISO },
  /* eslint-disable */
	props: {
		company: {
			type: Object,
			default: () => ({}),
		},
	},
  components: {
    Multiselect,
  },
	setup(props, { emit }) {
    const permissions = permissionsStore()
		const spinner = spinnerStore()
		const state = reactive({
			cover_url: '',
			logo_url: '',
			co_name: '',
			address: '',
			city: '',
			region_state: '',
			postal_code: '',
			country: '',
			country_code: '',
			co_description: '',
			website: '',
			corporate_video_url: '',
			privacy_policy_url: '',
			coverFile: null,
			coverLogo: null,
      contract_start_date: null,
      contract_end_date: null,
      contract_alert_emails: [],
      num_max_recruiters: 0,
      multi_store:false
		})

    onMounted(() => {
     if (props.company) {
				state.cover_url = props.company.cover_url
				state.logo_url = props.company.logo_url
				state.co_name = props.company.co_name
				state.co_description = props.company.co_description
				;(state.address = props.company.address), (state.city = props.company.city), (state.region_state = props.company.region_state), (state.postal_code = props.company.postal_code)
				state.country = props.company.country
				state.corporate_video_url = props.company.corporate_video_url
				state.privacy_policy_url = props.company.privacy_policy_url
				state.country_code = props.company.country_code
				state.website = props.company.website
        state.contract_start_date = props.company.contract_start_date ? parseISO(props.company.contract_start_date) : null
        state.contract_end_date = props.company.contract_end_date ? parseISO(props.company.contract_end_date) : null
        state.contract_alert_emails = props.company.contract_alert_emails
        state.num_max_recruiters=  props.company.num_max_recruiters,
        state.multi_store = props.company.multi_store
			}
		})

		const triggerInput = (id) => {
			let input = document.getElementById(id)
			input.click()
		}
		const onFileChange = (e, key) => {
			const file = e.target.files[0]
			key === 'cover' ? (state.coverFile = file) : (state.coverLogo = file)
			key === 'cover' ? (state.cover_url = URL.createObjectURL(file)) : (state.logo_url = URL.createObjectURL(file))
		}
		const getExt = (filename) => {
			return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename
		}
		const saveForm = () => {
      console.log("CONTRACT START DATE LENGTH:",state.contract_start_date)
			spinner.show()
			let formData = new FormData()
			let ext = ''
			if (props.company.logo_url != state.logo_url) {
				ext = getExt(state.coverLogo.name)
				formData.append('fileLogo', state.coverLogo, state.coverLogo.name ?? 'upload_logo.' + ext)
			}
			if (props.company.cover_url != state.cover_url) {
				ext = getExt(state.coverFile.name)
				formData.append('fileCover', state.coverFile, state.coverFile.name ?? 'upload_logo.' + ext)
			}

			formData.append(
				'json',
				JSON.stringify({
					coid: props.company.coid,
					co_name: state.co_name,
					co_description: state.co_description,
					address: state.address,
					city: state.city,
					postal_code: state.postal_code,
					country: state.country,
					region_state: state.region_state,
					country_code: state.country_code,
					corporate_video_url: state.corporate_video_url,
					indeed_opt_out: props.company.indeed_opt_out,
					indeed_apply_opt_out: props.company.indeed_apply_opt_out,
					website: state.website,
					privacy_policy_url: state.privacy_policy_url,
          contract_start_date: state.contract_start_date ? dayjs(state.contract_start_date).format('YYYY-MM-DD') : undefined,
          contract_end_date: state.contract_end_date ? dayjs(state.contract_end_date).format('YYYY-MM-DD') : undefined,
          email_addresses: state.contract_alert_emails ?? [],
          num_max_recruiters: state.num_max_recruiters
				})
			)

			// TODO: post for new company
			sbapibackoffice
				.put('/company-data', formData)
				.then((result) => {
					emit('campaignSaved', { status: result.status, co_name: state.co_name, coid: props.company.coid })
				})
				.catch((err) => {
					emit('campaignSaved', err)
				})
		}
		return {
			...toRefs(state),
			triggerInput,
			onFileChange,
			saveForm,
			permissions,
      handleTagCreate: (tag) => {
        console.log('tag', tag)
      },
		}
	},
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
input.multiselect-search,
.multiselect-search {
  background: transparent !important;
  background-color: transparent !important;
}
.flex-start {
  display: flex;
  align-items: stretch;
  .multiselect-wrapper {
    height: 100% !important;
    cursor: pointer !important;
  }
}
.multiselect {
  background: transparent;
  color: white;
  height: 41.5px !important;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: none !important;
  outline: none !important;
  &.search-active {
    flex: 1 !important;
    display: flex;
    height: unset !important;
    max-height: 100% !important;
    align-items: center;
    justify-content: center;

    .multi-label-container {
      height: 100%;
      .multi-label {
        margin: 5px;
        max-width: 60%;
      }
    }
    .multi-label-container {
      margin: 0;
      width: calc(100% - 40px) !important;
    }
  }

  input {
    background: transparent !important;
  }

  &.is-active {
    box-shadow: none !important;
    outline: none !important;
  }
}

.multiselect-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.multiselect-dropdown {
  background: #004278;
  color: white;
  z-index: 999999;
  box-shadow: none !important;
  outline: none !important;
  max-height: calc(52.5px * 6);

  input {
    &:focus,
    &:focus-visible,
    &:hover {
      box-shadow: none !important;
      outline: none !important;
    }

    &::placeholder {
      color: white !important;
    }
  }
}

.multiselect-multiple-label span.label {
  font-size: 12px;
  color: white;
  background: var(--info);
  padding: 5px 10px;

  border-radius: 3px;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
}

ul.multiselect-options li.multiselect-option {
  font-size: 14px;
  padding: 0 !important;
  min-height: 0 !important;

  span {
    padding: 10px 15px !important;
  }

  span.disabled {
    display: none;
  }
}

.multiselect-option.is-pointed {
  //background: rgba(16, 252, 224, 0.25) !important;
  background: linear-gradient(90deg, rgba(66, 186, 222, 0.2) 60%, rgba(2, 0, 36, 0) 100%) !important;
  color: white;
  position: relative;
}

.multiselect-caret {
  background: white;
  margin-right: 10px;
}

.multiselect-option.is-selected {
  background: #4c75997b !important;
  position: relative;
}

li.is-selected span.close {
  position: absolute !important;
  right: 5px;
  color: #b8cee0;
}

.multiselect-clear-icon {
  background: #b8cee0;
}

.multiselect .multiselect-clear:hover .multiselect-clear-icon {
  background: white;
}

.multiselect-tag__remove {
  color: white;
  padding: 0 1rem;
}

.multiselect input {
  color: white;
}
</style>

<style lang="scss" scoped>
@import '../../../assets/scss/lists.scss';
input > span {
	opacity: 0;
	display: none;
}
.img-logo {
	width: 150px;
	height: 150px;
	background-repeat: no-repeat !important;
	background-size: contain !important;
	background-position: center !important;
}
.img-cover {
	width: 100%;
	height: 250px;
	background-repeat: no-repeat !important;
	object-fit: cover !important;
	background-position: center !important;
}
.placeholder-logo {
	background-size: contain !important;
	background: url('../../../assets/logo_placeholder.png') no-repeat center !important;
}
.placeholder-cover {
	background: url('../../../assets/cover_placeholder.png') no-repeat center !important;
	object-fit: cover !important;
	background-position: center !important;
	background-size: cover !important;
	-ms-background-size: cover !important;
	-o-background-size: cover !important;
	-moz-background-size: cover !important;
	-webkit-background-size: cover !important;
}
.files {
	align-items: flex-end;
	height: 250px;
	display: flex;
	position: relative;
	background-repeat: no-repeat !important;
	object-fit: cover !important;
	background-position: center !important;
	background-size: cover;
	-ms-background-size: cover;
	-o-background-size: cover;
	-moz-background-size: cover;
	-webkit-background-size: cover;
}
.absolute {
	padding: 2px;
	position: absolute;
	width: 154px;
	height: 154px;
	top: 50%;
	transform: translate(0%, -50%);
	left: 1.5%;
	align-content: center;
	align-items: center;
	flex-direction: column;
	background: rgba(1, 213, 255, 0.16);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	border: 1px solid rgba(255, 255, 255, 0.5);
	input {
		position: absolute;
		width: 150px;
		height: 150px;
		opacity: 0;
		display: flex;
		z-index: 9999;
		position: absolute;
		top: 0;
		bottom: 0;
	}
	label[for='logo'] {
		text-align: center;
		background: rgba(255, 255, 255, 0.2);
		box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
		backdrop-filter: blur(8px);
		-webkit-backdrop-filter: blur(8px);
		margin-top: calc(85% - 12.5px);
		width: 100%;
		color: #0a3e66;
		font-size: 14px;
		left: 0px;
		line-height: 1.5;
		height: 25px;
	}
}
.input-cover {
	position: absolute;
	left: 180px;
	height: 100%;
	width: 100%;
	opacity: 0;
}
label[for='cover'] {
	text-align: center;
	background: rgba(255, 255, 255, 0.2);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(8px);
	-webkit-backdrop-filter: blur(8px);
	position: relative;
	margin-bottom: 12.5px;
	width: 100%;
	color: #0a3e66;
	font-size: 14px;
	left: 0px;
	line-height: 1.5;
	height: 25px;
}

#multi_store-anagrafica {
  background: #17a2b890!important;
  margin-top: 5px;
  & + label {
    min-width: max-content;
    color:rgba(255, 255, 255, 0.85)!important;
     margin-top: 5px;
  }
}

</style>
