<template>
  <div class="stand" style="width: 100%">
    <form>
      <div class="group group-100" style="margin-bottom: 50px">
        <span>
          <div v-show="formFields[0].value" id="coverDiv" class="cover-preview" @click="triggerInput(formFields[0].key + '-input')" />
          <div v-show="!formFields[0].value" class="cover-preview" @click="triggerInput(formFields[0].key + '-input')">
            <svg style="width: 45px; height: 45px; margin-bottom: 15px" viewBox="0 0 24 24">
              <path
                fill="white"
                d="M4,5H7L9,3H15L17,5H20A2,2 0 0,1 22,7V19A2,2 0 0,1 20,21H4A2,2 0 0,1 2,19V7A2,2 0 0,1 4,5M13.09,9.45L11.05,12.18L12.6,14.25L11.73,14.91L9.27,11.64L6,16H18L13.09,9.45Z"
              />
            </svg>
            <span>Copertina: immagine di almeno 1920 x 380 px o video in formato mp4.</span>
          </div>
          <input v-show="false" :id="formFields[0].key + '-input'" type="file" @change="onFileChange($event, formFields[0].key)">
        </span>
      </div>
      <div class="wrapper">
        <div style="margin-top: -25px" @click="triggerInput(formFields[3].key + '-input')">
          <div style="margin-bottom: 15px">
            Logo: immagine quadrata di almeno 400 x 400 px
          </div>
          <div v-show="formFields[3].value" id="logoDiv" class="logo-preview" />
          <div v-show="!formFields[3].value" class="logo-preview">
            <svg style="width: 30px; height: 30px; margin-bottom: 15px" viewBox="0 0 24 24" @click="triggerInput(formFields[3].key + '-input')">
              <path
                fill="white"
                d="M4,5H7L9,3H15L17,5H20A2,2 0 0,1 22,7V19A2,2 0 0,1 20,21H4A2,2 0 0,1 2,19V7A2,2 0 0,1 4,5M13.09,9.45L11.05,12.18L12.6,14.25L11.73,14.91L9.27,11.64L6,16H18L13.09,9.45Z"
              />
            </svg>
          </div>
          <input v-show="false" :id="formFields[3].key + '-input'" type="file" @change="onFileChange($event, formFields[3].key)">
        </div>
        <div>
          <div class="group group-100">
            <input :value="formFields[1].value" class="inputMaterial" type="text">
            <span class="highlight" />
            <span class="bar" />
            <label>{{ formFields[1].name }}</label>
          </div>
          <div class="group group-100">
            <textarea class="inputMaterial" :value="formFields[2].value" rows="5" />
            <span class="highlight" />
            <span class="bar" />
            <label>{{ formFields[2].name }}</label>
          </div>
        </div>
      </div>

      <div class="accent-background">
        <vue-collapsible-panel-group>
          <vue-collapsible-panel v-for="(section, index) in sections" :key="section.title + index" :expanded="false" @click="checkContent(section.title, section.content)">
            <template #title>
              <div class="wrapper-badge">
                <div class="badges">
                  <div class="badge current">
                    {{ section.content.length }}
                  </div>
                </div>
                <div style="text-align: left; margin-left: 15px; width: 100%">
                  {{ section.title }}
                </div>
                <div v-if="section.content.length === 0">
                  <button class="btn current" @click="addDynamic(section.title)">
                    Aggiungi {{ section.title }}
                  </button>
                </div>
              </div>
            </template>
            <template #content>
              <div>parent: {{ sezioni }}</div>
              <span>
                <SezioniStand v-model:content="sezioni" />
              </span>
            </template>
          </vue-collapsible-panel>
        </vue-collapsible-panel-group>
      </div>
    </form>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import SezioniStand from './stand-dynamic/SezioniStand.vue'
/* eslint-disable */
export default {
	components: {
		SezioniStand,
	},
	setup() {
		const state = reactive({
			sections: [
				{
					title: 'Sezioni',
					content: [],
				},
				{
					title: 'Schede',
					content: [
						{
							giventTitle: '',
							image: null,
							video: null,
							descrizione: '',
						},
					],
				},
				{
					title: 'Sezioni',
					content: [
						{
							giventTitle: '',
							descrizione: '',
						},
					],
				},
			],
			formFields: [
				{ key: 'cover', type: 'file', name: 'Copertina', value: null },
				{ key: 'title', type: 'text', name: 'Titolo', value: '' },
				{
					key: 'desc',
					type: 'textarea',
					name: 'Descrizione',
					value: '',
				},
				{
					key: 'logo',
					type: 'file',
					name: 'Logo',
					value: null,
				},
			],
			cards: [],
			showCards: false,
		})

		const getClass = (name) => {
			switch (name) {
				case 'title':
					return 'group-50'
				case 'logo':
					return 'group-50'
				case 'desc':
				case 'cover':
					return 'group-100'
				default:
					return 'group-50'
			}
		}

		const onFileChange = (e, key) => {
			const file = e.target.files[0]
			for (let field of state.formFields) {
				if (field.key === key) {
					field.value = URL.createObjectURL(file)
					setBg(key, field.value)
				}
			}
		}
		const setBg = (id, val) => {
			let input = id === 'logo' ? document.getElementById('logoDiv') : document.getElementById('coverDiv')
			input.style.backgroundImage = `url("${val}")`
		}
		const triggerInput = (id) => {
			let input = document.getElementById(id)
			input.click()
		}

		const addCards = () => {
			let count = state.cards.length + 1
			state.cards.push({
				title: 'Scheda ' + count,
				image: null,
				url: null,
				descrizione: '',
			})
			state.showCards = true
		}

		const sezioni =
			'{"title":"sezione titò","description":"<p>desc sezione</p>","schede":[{"name":"Scheda 1","image":"section_tab1image.png","video":"https://www.google.it","description":"<p>desc scheda 1</p>","index":1},{"name":"Scheda 2","image":"section_tab2image.png","video":"https://www.google.it","description":"<p>desc scheda 2</p>","index":2}]}'

		const checkContent = (title, length) => {
			if (!length || length.length === 0) {
				addDynamic(title)
			}
		}
		const addDynamic = (title) => {
			let field = state.sections.find((section) => section.title === title)
			switch (title) {
				case 'Sezioni':
					field.content.push({
						giventTitle: '',
						descrizione: '',
						schede: [
							{
								schedaTitle: 'Scheda 1',
								image: null,
								video: null,
								descrizione: '',
							},
						],
					})
			}
		}

		return {
			...toRefs(state),
			onFileChange,
			triggerInput,
			getClass,
			addCards,
			addDynamic,
			checkContent,
			sezioni,
		}
	},
}
</script>

<style lang="scss">
.stand {
	.choices-wrapper {
		width: 100%;
		display: flex;
		align-content: center;
		justify-content: center;
		button {
			cursor: pointer;
			border: 1px solid #0173bf;
			background: #0173bf;
			color: white;
			margin: 0 15px;
			height: 36px;
			min-width: 64px;
			padding: 0 16px;
			font-size: 0.875rem;
			vertical-align: middle;
			white-space: nowrap;
			text-transform: uppercase;
			color: white;
			border-radius: 4px;
			box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
		}
	}
	.cover-preview {
		border: 1px solid rgba(255, 255, 255, 0.15);
		position: relative;
		width: 100%;
		height: 200px;
		align-items: center;
		display: flex;
		justify-content: center;
		svg {
			width: 30px;
			height: 30px;
		}
		div {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-items: center;
		}
		img {
			height: 100%;
			box-sizing: border-box;
			max-width: 100%;
			max-height: 100%;
			width: 1;
		}
	}
	.img-wrapper {
		display: flex;
		text-align: center;
		justify-content: center;
		justify-items: center;
		align-items: center;
	}
	.wrapper {
		align-items: center;
		display: flex;
		width: 100%;
		align-content: center;
		justify-content: center;
		justify-items: center;
		div {
			width: 100%;
		}
		div.logo-preview {
			object-fit: cover;
			width: 150px;
			height: 150px;
			border: 1px solid rgba(255, 255, 255, 0.15);
			display: flex;
			justify-content: center;
			text-align: left;
		}
		div {
			display: flex;
			flex-direction: column;
			justify-items: center;
			align-items: center;
			img {
				width: 130px;
				height: 130px;
			}
		}
	}
	.accent-background {
		width: 100%;
		margin: 65px 5px 45px 8px;
		.vcpg {
			border-radius: 5px;
			border: 1px solid rgba(255, 255, 255, 0.15);
		}
		.vcp__header {
			display: flex;
			//flex-direction: row-reverse;
			background-image: radial-gradient(circle at 50% 100%, transparent, #00ceac15);
			background: radial-gradient(circle at 50% 100%, transparent, #00ceac15);
		}
		.vcp--expandable .vcp__header-icon {
			margin-top: 1px;
			margin-right: 10px;
			transform: rotate(0deg) !important;
		}
		.vcp--expanded .vcp__header-icon {
			transform-origin: center;
			transform: rotate(180deg) !important;
			margin-right: 15px;
			margin-top: 1px;
		}
	}
	.wrapper-badge {
		align-items: center;
		display: flex;
		flex: 1;
		justify-content: center;
		div.badges {
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: center;
			justify-items: center;
			justify-content: center;
			.badge {
				background-color: #42bade;
				color: white;
				width: 35px;
				height: 35px;
				border-radius: 50%;
				text-align: center;
				line-height: 35px;
				margin: 0 10px 0 0px;
			}
		}
		.current {
			background: var(--accented);
		}
	}
}
</style>
