<template>
  <div @click="modalOptions = false">
    <form>
      <div
        v-for="(field, index) in formFields"
        :key="field.name + '_' + index"
        class="group"
        :class="field.type === 'text' || field.type === 'email' || field.type === 'select' ? 'group-50' : 'group-100'"
      >
        <div v-if="modalOptions && field.type === 'select'" class="modalOptions">
          <ul>
            <li v-for="(opt, index) in field.options" :key="opt + index">
              {{ opt }}
            </li>
          </ul>
        </div>
        <input
          v-if="field.type === 'text' || field.type === 'email' || field.type === 'select'"
          :value="field.value"
          class="inputMaterial"
          :type="field.type"
          @focus="showModalIfSelect(field.type)"
        >
        <textarea v-if="field.type === 'textarea'" class="inputMaterial" :value="field.value" :type="field.type" rows="5" />
        <span class="highlight" />
        <span class="bar" />
        <label>{{ field.name }}</label>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted } from 'vue'

export default {
  /* eslint-disable */
	props: {
		company: {
			type: Object,
			default: () => ({}),
		},
	},
	setup(props) {
		const state = reactive({
			formFields: [
				{ key: 'euro', type: 'number', name: 'euro', value: '' },
				{ key: 'formaGiuridica', type: 'select', options: [], name: 'Forma giuridica', value: '' },
				{
					key: 'ragioneSociale',
					type: 'text',
					name: 'Ragione Sociale',
					value: '',
				},
				{
					key: 'indirizzo',
					type: 'text',
					name: 'Indirizzo',
					value: '',
				},
				{
					key: 'comune',
					type: 'text',
					name: 'Comune',
					value: '',
				},
				{
					key: 'provincia',
					type: 'text',
					name: 'Provincia',
					value: '',
				},

				{
					key: 'cap',
					type: 'text',
					name: 'Cap',
					value: '',
				},
				{
					key: 'nazione',
					type: 'text',
					name: 'Nazione',
					value: '',
				},
				{
					key: 'pec',
					type: 'email',
					name: 'Pec',
					value: '',
				},
				{
					key: 'piva',
					type: 'text',
					name: 'Partita iva',
					value: '',
				},
				{
					key: 'cf',
					type: 'text',
					name: 'Codice Fiscale',
					value: '',
				},
				{
					key: 'policy',
					type: 'text',
					name: 'Privacy Policy',
					value: '',
				},
			],
		})
		const companiesType = ['Uno', 'Due', 'Tre']

		onMounted(() => {
			let field = state.formFields.find((field) => field.key === 'formaGiuridica')
			field.options = companiesType
			if (props.company) {
				for (let value in props.company) {
					for (let key in state.formFields) {
						if (value == state.formFields[key].key) {
							state.formFields[key].value = props.company[value]
						}
					}
				}
			}
		})
		let modalOptions = ref(false)
		const showModalIfSelect = (type) => {
			if (type === 'select') {
				modalOptions.value = true
			}
		}
		return {
			...toRefs(state),
			showModalIfSelect,
			modalOptions,
		}
	},
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/lists.scss';
.group {
	position: relative;
}
.modalOptions {
	top: 100%;
	border-radius: 3px;
	z-index: 30;
	position: absolute;
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	width: 100%;
	border: 1px solid rgba(255, 255, 255, 0.15);
	ul {
		width: 100%;
		height: 100%;
		padding: 0;
		margin: 0;
		list-style: none;
		background: radial-gradient(circle at 50% 100%, #008bdb56, #076fd03a);
		li {
			padding: 8px 10px;
			text-align: left;
			border-bottom: 0;
			transition: all 1s ease-in-out;
			position: relative;
		}
		li:hover::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			height: 0;
			width: 0;
			z-index: 1;
			border-top: 5px solid black;
			animation: grow 1s linear;
			animation-fill-mode: forwards;
		}
		@keyframes grow {
			100% {
				width: 100%;
			}
		}
	}
}
</style>
