<template>
  <transition name="modal-animation">
    <div v-if="modalActive" class="modal">
      <transition name="modal-animation-inner">
        <div v-show="modalActive" class="modal-inner" :class="{ spaceVertically: !notSure }">
          <div style="display: flex; align-items: center; justify-content: space-between">
            <div v-text="modalType? 'Gift Voucher': 'Accredita somma'" />
            <button v-if="notSure" class="closeBtn" @click="close">
              <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
              </svg>
            </button>
            <button v-else class="closeBtn" @click="notSure = true">
              <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M21,11H6.83L10.41,7.41L9,6L3,12L9,18L10.41,16.58L6.83,13H21V11Z" />
              </svg>
            </button>
          </div>
          <div v-if="!modalType" style="margin: 10px 0px;">
            Importo per  {{ sel[0].co_name }}
          </div>
           
          <span v-if="notSure" class="main-container">
            <div class="img-wrapper">
              <div class="rounded-full" :class="modalType? 'babbo':'money'" />
            </div>
            <div v-if="modalType" class="subtitle">Aziende selezionate: {{ sel.length }}</div>
            <div v-if="modalType" style="position: relative" class="modal-select">
              <Multiselect
                v-model="sel"
                mode="multiple"
                :options="allOpt"
                :object="true"
                label="co_name"
                value-prop="coid"
                :hide-selected="false"
              >
                <template #multiplelabel="{ values }">
                  <div class="multiselect-multiple-label">
                    <span v-if="values.length === 1">1 campagna selezionata</span>
                    <span v-else>{{ values.length }} campagne selezionate</span>
                  </div>
                </template>
                <template #option="{ option, isSelected }">
                  <div class="option-container">
                    <input type="checkbox" :checked="isSelected(option)">
                    <div class="opt-label">
                      {{ option.co_name }}
                    </div>
                  </div>
                </template>
              </Multiselect>
            </div>
            <div class="inputs" style="margin: 10px 0px;">
              <Multiselect
                v-model="euro"
                :disabled="euro[0]"
                mode="tags"
                max="1"
                :can-clear="true"
                :close-on-select="true"
                :clear-on-select="true"
                :clear-on-blur="true"
                :searchable="true"
                :create-option="true"
                :options="[50, 100, 150, 200, 250, 500, 1000]"
                :placeholder="!modalType? 'Scegli o inserisci un importo a favore di '+ sel[0].co_name : 'Scegli un importo o inseriscine uno e premi INVIO.'"
                input-type="number"
              >
                <template #tag="{ option, handleTagRemove }">
                  <div
                    class="multiselect-tag"
                    @click="handleTagRemove(option, $event)"
                  >
                    <span>
                      € {{ formatNumber(option.value) }}
                    </span>
                    <span class="multiselect-tag-remove">
                      <span class="multiselect-tag-remove-icon" />
                    </span>
                  </div>
                </template>
                <template #option="{ option }">
                  <span>€ {{ formatNumber(option.value) }}</span>
                </template>
              </Multiselect>
         
            </div>
         
          </span>
          <div v-if="notSure" class="footer-btn">
            <material-button :text="modalType? 'Invia il gift voucher' : 'Accredita la somma'" type="danger" :disabled="isDisabled" @click="notSure = false" />
          </div>

          <span v-if="!notSure" class="spaceVertically" style="display: flex; flex-direction: column">
            <div class="subtitle">
              <p>Sei sicuro?</p>
              <p v-if="modalType">I regali non si possono richiedere indietro...</p>
            </div>
            <div class="img-wrapper">
              <div class="rounded-full" :class="modalType? 'regalo':'wings'" />
            </div>
            <div class="spaceVertically" style="align-content: flex-end; justify-content: center">
              <strong>I {{ euro[0] }} € accreditati <span v-text="sel.length === 1 ? 'all\'azienda ' + sel[0].co_name + ' ' : 'alle ' + sel.length + ' aziende'" /> non potranno essere più
                essere scalati</strong>
            </div>
            <div class="footer-btn spaceVertically">
              <material-button v-if="modalType" :text="'Sono sicuro, invia il gift voucher'" type="danger" :disabled="isDisabled" @click="sendGift" />
              <material-button v-else :text="'Sono sicuro, accredita il denaro'" type="danger" :disabled="isDisabled" @click="sendMoney" />
            </div>
          </span>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { spinnerStore } from '@/stores/spinner'
import { sbapibackoffice } from '@/api'
import { ref, computed } from 'vue'
import Multiselect from '@vueform/multiselect'
export default {
  components: { Multiselect },
  props: {
    modalActive: {
      type: Boolean,
      default: () => false
    },
    modalType: {
      type: Boolean,
      default: () => false
    },
    selected: {
      type: Array,
      default: () => []
    },
    allOpt: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:selected', 'close'],
  setup(props, { emit }) {
    const spinner = spinnerStore()
    const sel = computed({
      get: () => props.selected,
      set: (value) => emit('update:selected', value)
    })
    const close = () => {
      emit('close', { close: true })
    }
    let notSure = ref(true)
    let obj = null
    let activeBar = false
    let euro = ref([])
    const getOptVisibility = (option) => {
      let index = props.selected.indexOf(option)
      return index <= 4
    }
    const getMoreVisibility = (option) => {
      let index = props.selected.indexOf(option)
      return index === 5
    }
    const dropdownShouldOpen = (VueSelect) => {
      if (props.selected.length != props.allOpt.length) {
        return VueSelect.open
      }
      return VueSelect.search.length !== 0 && VueSelect.open
    }
    const deleteOpt = (opt) => {
      let index = sel.value.indexOf(opt)
      sel.value.splice(index, 1)
      emit('update:selected', sel.value)
    }
    const sendGift = () => {
      spinner.show()
      let ArrayGift = []
      sel.value.forEach((company) =>
        ArrayGift.push({
          coid: company.coid,
          transactionAmount: euro?.value[0],
          transactionType: 'giftVoucher' 
        })
      )
      obj = {
        ArrayGift: ArrayGift
      }
      sbapibackoffice
        .post('/companygiftvoucher', obj)
        .then(() => {
          emit('close', obj)
        })
        .catch((err) => emit('close', { err: err }))
        .finally(() => {
          spinner.hide()
        })
    }
    const sendMoney = () => {
      spinner.show()
      let obj = {
        coid: sel.value[0].coid,
        transactionAmount: euro?.value[0],
        transactionType: 'manualBankTransfer' 
      }
      sbapibackoffice
        .post('/company/accredit', obj)
        .then(() => {
          emit('close', obj)
        })
        .catch((err) => emit('close', { err: err }))
        .finally(() => {
          spinner.hide()
        })
    }
    const isDisabled = computed(() => {
      if (!euro?.value[0] || sel.value.length === 0) {
        return true
      }
      return false
    })
    const formatNumber = (number) => {
      number = Number(number)
      var opzioniFormattazione = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }

      return number.toLocaleString('it-IT', opzioniFormattazione)
      
    }
    return {
      formatNumber,
      close,
      euro,
      activeBar,
      sel,
      getOptVisibility,
      getMoreVisibility,
      dropdownShouldOpen,
      deleteOpt,
      sendGift,
      isDisabled,
      notSure,
      sendMoney
    }
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
@import '../assets/scss/lists.scss';
</style>
<style lang="scss">
.modal {
position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #0000006e;
	z-index: 999;
  .footer-btn {
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 20px;
  }
  .spaceVertically {
display: flex !important;
flex: 1;
flex-direction: column;
}
  .modal-inner {
    display:flex;
    flex-direction: column;
    background-color: #ffffff;
    color: black;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
    width: 550px;
    max-width: 550px;
    min-height: 450px;
    overflow: hidden;
    .main-container {
    display: flex;
    flex:1;
    flex-direction: column;
    div.inputs{
    flex:1;
    }
    }
    .material-button {
    font-weight: 600;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
    padding: 0 16px;
    min-width: 64px;
    height: 45px;
    vertical-align: middle;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: uppercase;
    color: white;
    background-color: crimson;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    font-size: 12px;
    font-weight: 500;
    line-height: 25px;
    overflow: hidden;
    outline: none;
    cursor: pointer;
    transition: box-shadow 0.2s;
    &::-moz-focus-inner {
    border: none;
    }
    &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: crimson;
    opacity: 0;
    transition: opacity 0.2s;
    }
    &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    padding: 50%;
    width: 32px;
    height: 32px;
    background-color: crimson;
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
    transition: opacity 1s, transform 0.5s;
    }
    &:hover {
    background-color: rgb(250, 44, 86);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    &::before {
      opacity: 0.08;
    }
    &:focus {
      &::before {
        opacity: 0.3;
      }
    }
    }
    &:focus {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    &::before {
      opacity: 0.24;
    }
    }
    &:active {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    &::after {
      opacity: 0.32;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0s;
    }
    }
    &:disabled {
    color: white;
    background-color: grey;
    box-shadow: none;
    cursor: initial;
    &::before {
      opacity: 0;
    }
    &::after {
      opacity: 0;
    }
    }
    }
  }

.wrapper-text {
display: flex;
flex: 1;
flex-direction: column;
}
.closeBtn {
  cursor: pointer;
  border: 0;
  outline: 0;
background: transparent;
border-radius: 5px;
height: 25px;
width: 25px;
display: flex;
justify-content: center;
align-items: center;
svg {
  max-height: 18px;
width: auto;
  path {
    fill:#393939;
  }
}
}
.subtitle {
text-align: center;
padding-bottom: 25px;
}
.badge {
color: white;
font-weight: 600;
border: 1px solid crimson;
margin: 0 6px;
padding: 4px 8px;
border-radius: 8px;
}
.group {
position: relative;
margin-top: 40px;
padding-bottom: 25px;
}

.multiselect{
  .multiselect-tag {
    border-color: #17a2b8;
    background: #17a2b8;
  }
  &.is-active {
  box-shadow: none;
  outline: none;
  }
  .multiselect-dropdown {
    background: rgb(245, 245, 245);
    color: black;
    max-height: 120px;
  }
  ::-webkit-scrollbar {
    width: 2px;
  }
  .is-selected, .is-pointed {
    background: rgb(221, 221, 221);
    color: rgb(0, 0, 0);
}
  .option-container {
		width: 100%;
		display: flex;
		align-items: center;
		position: relative;

		input[type=checkbox] {
      display: block;
			transform: scale(.8) !important;

		}

		.opt-label {
			padding-left: 10px;
			min-width: fit-content;
      font-size: 14px;
		}

		.selection {
			font-size: 9px;
			position: absolute;
			right: 5px;
			opacity: 0;

			&.selected {
				opacity: 1;
			}
		}
	}
}

.babbo {
background: var(--accented);
background-image: url('../assets/babbo.png') !important;
}
.money {
background: #C0E1CA;
background-image: url('../assets/money.png') !important;
}
.regalo {
background: #42c9de;
background-image: url('../assets/regalo.png') !important;
}
.wings {
background: #42c9de;
background-image: url('../assets/money-with-wings.gif') !important;
}
.img-wrapper {
padding: 10px 0;
flex-direction: column;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
span {
padding-bottom: 25px;
font-size: 20px;
}
.rounded-full {
border-radius: 100%;
width: 100px;
height: 100px;
display: flex;
position: relative;
align-content: center;
justify-content: center;
border: 1px solid #393939;
//background-image: url('https://tinypic.host/images/2022/04/14/icon450_1153941629-1.png');
background-repeat: no-repeat;
//background-position: center;
background-size: cover;
}
    }
.multiselect-placeholder,
    input::placeholder {
      font-size: 14px;
    }
}

.modal-animation-enter-active,
.modal-animation-leave-active {
transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-enter-from,
.modal-animation-leave-to {
opacity: 0;
}

.modal-animation-inner-enter-active {
transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}

.modal-animation-inner-leave-active {
transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-inner-enter-from {
opacity: 0;
transform: scale(0.8);
}

.modal-animation-inner-leave-to {
transform: scale(0.8);
}
</style>