<template>
  <div class="sections-container">
    <div style="display: flex">
      <div v-if="false" class="tabs">
        <div class="tab">
          <div v-for="(tab, index) in companySections" :key="index" :class="{ active: tabActive === 'tab-' + index }" @click="tabActive = 'tab-' + index">
            <span class="light" />
            <span>{{ tab }}</span>
          </div>
        </div>
      </div>

      <div class="panels">
        <AnagraficaForm v-if="tabActive == 'tab-0'" :company="company" @campaignSaved="campaignSaved($event)" />
        <FatturazioneForm v-if="tabActive == 'tab-1'" :company="company" />
        <StandForm v-if="tabActive == 'tab-3'" :company="company" />
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, computed } from '@vue/runtime-core'
import FatturazioneForm from './FatturazioneForm.vue'
import AnagraficaForm from './AnagraficaForm.vue'
import StandForm from './StandForm.vue'
/* eslint-disable */
export default {
	props: {
		company: {
			type: Object,
			default: () => ({}),
		},
	},
	components: {
		AnagraficaForm,
		FatturazioneForm,
		StandForm,
	},
	setup(props, { emit }) {
		const state = reactive({
			checked: null,
			tabActive: 'tab-0',
		})
		const companySections = ['Anagrafica', 'Fatturazione', 'Brand', 'Stand']
		const check = (cid, index) => {
			console.log(state.checked)
			if (state.checked === cid + '-' + index) {
				state.checked = null
			} else {
				state.checked = cid + '-' + index
			}
		}
		const cid = computed(() => {
			if (props.company && props.company.cid) {
				return props.company.cid
			}
			return ''
		})
		const campaignSaved = (e) => {
			console.log('sectionsForm', e)
			emit('campaignSaved', e)
		}
		return {
			companySections,
			...toRefs(state),
			cid,
			check,
			campaignSaved,
		}
	},
}
</script>

<style lang="scss" scoped>
.footer {
	padding: 5px 35px 5px 0;
	display: flex;
	flex: 1;
	justify-content: flex-end;
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	button {
		padding: 8px 15px;
	}
}
.sections-container {
	display: flex;
	justify-items: center;
	flex-direction: column;

	.tabs {
		padding: 12px 0px;
		display: flex;
		flex: 0 0 12%;
	}
	.tab {
		flex: 1;
		width: 100%;
	}
	.tabs .tab div {
		text-align: left;
		padding: 15px 20px 15px 40px;
		margin-bottom: 10px;
		color: white;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
		cursor: pointer;
		position: relative;
		vertical-align: middle;
		font-weight: 700;
		transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
		display: flex;
		flex: 1;
	}
	.tabs .tab div:hover {
		background: #42bade10;
	}
	div .tab div:last-of-type {
		margin-bottom: 0;
	}

	.tabs .tab div:hover {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	}

	.tabs .tab div span.light {
		height: 10px;
		width: 10px;
		position: absolute;
		top: 20px;
		left: 15px;
		background-color: #006caf;
		border-radius: 100%;
		transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
	}

	.tabs .tab div.active span.light {
		background-color: var(--accented);
		left: 0;
		height: 100%;
		width: 3px;
		top: 0;
		border-radius: 0;
	}

	.tabs .tab div.active {
		color: var(--accented);
		padding: 15px 20px 15px 20px;
		background: radial-gradient(circle at 50% 100%, #008bdb0e, #076fd015);
	}

	.panels {
		display: flex;
		flex: 1;
		padding: 12px 50px;
		max-height: 50vh;
		overflow-y: auto;
		overflow-x: hidden;
		/* Track */
		.panels::-webkit-scrollbar-track {
			background: #f1f1f1;
		}

		/* Handle */
		::-webkit-scrollbar-thumb {
			background: rgb(255, 255, 255);
		}

		/* Handle on hover */
		::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
	}
	.panels::-webkit-scrollbar-track {
		background: white;
	}
}
.panel-header-container {
	display: flex;
	align-items: center;
	justify-items: center;
	div {
		display: flex;
	}
}
.expansion-panel.selected-section .arrow-inside-section svg {
	transform: rotate(90deg);
	transition: all 0.2s ease-in-out;
}
.arrow-inside-section svg {
	transform: rotate(0deg);
	transition: all 0.2s ease-out;
}
</style>