<template>
  <div class="sezioni-stand">
    <vue-collapsible-panel-group>
      <vue-collapsible-panel v-for="(field, index) in computedForm" :key="field + index" :expanded="field.name === ''">
        <template #title>
          {{ field.title === '' ? 'New' : field.title }}
        </template>
        <template #content>
          <div class="group" style="width: 100%; margin-top: 50px">
            <div class="group group-100">
              <input v-model="field.title" class="inputMaterial" type="text">
              <span class="highlight" />
              <span class="bar" />
              <label>Titolo</label>
            </div>
            <div class="group group-100">
              <QuillEditor v-model="field.descrizione" />
            </div>
            <!-- Schede -->
            <div v-for="(scheda, i) in field.schede" :key="'scheda-' + i" class="group group-flex">
              <div class="group group-100">
                <input :value="scheda.schedaTitle" class="inputMaterial" type="text">
                <span class="highlight" />
                <span class="bar" />
                <label>Titolo scheda</label>
              </div>

              <div class="group group-50">
                <div v-if="!scheda.image" style="height: 100px">
                  <p>Immagine</p>
                  Requisiti: almeno 1180x440, in formato .jpg o .png
                </div>
                <div v-show="scheda.image" :id="'immagine-' + i" style="min-height: 100px; width: 100%" />
                <div style="margin-top: 15px">
                  <button v-if="!scheda.image" class="btn add" @click.prevent.stop="triggerInput('input-image-' + i)">
                    Carica immagine
                  </button>
                  <button v-else class="btn remove" @click.prevent.stop="scheda.image = null">
                    Rimuovi immagine
                  </button>
                </div>
                <input v-show="false" :id="'input-image-' + i" type="file" @change.stop="onFileChange($event, index, i)">
              </div>
            </div>
          </div>
        </template>
      </vue-collapsible-panel>
    </vue-collapsible-panel-group>

    <button @click.prevent.stop="save">
      Salva
    </button>
  </div>
</template>

<script>
import { computed } from 'vue'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

export default {
  /* eslint-disable */
	components: {
		QuillEditor,
	},
	props: ['content'],
	emits: ['update:content'],
	setup(props, { emit }) {
		const stand = [
			{
				name: 'Ita trasporto aereo',
				description:
					'Italia Trasporto Aereo è una società controllata dal Ministero dell’Economia e delle Finanze per l’esercizio dell’attività nel settore del trasporto aereo. ITA è attiva da novembre 2020. Il nostro obiettivo è...',
				logo: '',
			},
		]

		const computedForm = computed(() => {
			console.log(JSON.parse(props.content))
			return JSON.parse(props.content)
		})

		const save = () => {
			let obj = {
				ciaone: 'proprio',
			}
			emit('update:content', obj)
		}

		const triggerInput = (id) => {
			let input = document.getElementById(id)
			input.click(function (e) {})
		}

		const onFileChange = (e, index, i) => {
			const file = e.target.files[0]
			copyForm.value[index].schede[i].image = URL.createObjectURL(file)
			console.log('image', copyForm.value[index].schede[i].image)
			setBg(i, copyForm.value[index].schede[i].image)
		}
		const setBg = (id, val) => {
			let div = document.getElementById('immagine-' + id)
			div.style.backgroundImage = `url("${val}")`
		}

		return {
			triggerInput,
			onFileChange,
			computedForm,
			save,
		}
	},
}
</script>

<style lang="scss">
.sezioni-stand .vcpg .vcp__header {
	display: flex;
	flex-direction: row;
	background-image: transparent !important;
	background: transparent !important;
}
.ql-toolbar.ql-snow {
	text-align: left;
}
.ql-toolbar > *,
.ql-snow .ql-picker,
.ql-toolbar svg path,
.ql-toolbar svg > *,
.ql-stroke .ql-snow .ql-stroke {
	stroke: white !important;
	color: white;
}
.ql-snow.ql-toolbar button {
	border-radius: 30px;
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-items: center;
	svg {
		width: 18px;
		height: 18px;
	}
}
.ql-picker,
.ql-picker-label,
.ql-formats {
	background: transparent;
}

.ql-picker-label:hover {
	background: #032f56;
	background: #00ceac20;
	color: white;
	border-radius: 5px;
}
.ql-snow .ql-picker-options,
.ql-picker-options {
	background: #032f56;
	color: white;
}
.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar button:hover {
	background: #00ceac20;
}
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
	color: white;
	background: #00ceac;
}

.group-flex {
	display: flex;
	flex-wrap: wrap;
}

.add {
	color: white;
	background: #00ceac;
}
.remove {
	background: rgb(225, 90, 118);
	color: white;
}
</style>